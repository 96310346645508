import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

const miod = {
  color: '#ca9502'
}

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex' }}>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <div>
              <h2 className="miod"><strong style={miod}>8 urodziny wspólnoty effatha</strong> </h2>
              <p><em>Poniżej galeria zdjęć z tego pięknego spotkania:</em> </p>

              <img src={'/efffatha-urodziny-2019/1.jpeg'} alt={'1'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/2.jpeg'} alt={'2'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/3.jpeg'} alt={'3'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/4.jpeg'} alt={'4'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/5.jpeg'} alt={'5'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/6.jpeg'} alt={'6'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/7.jpeg'} alt={'7'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/8.jpeg'} alt={'8'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/9.jpeg'} alt={'9'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/10.jpeg'} alt={'10'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/11.jpeg'} alt={'11'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/12.jpeg'} alt={'12'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/13.jpeg'} alt={'13'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/14.jpeg'} alt={'14'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/15.jpeg'} alt={'15'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/16.jpeg'} alt={'16'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/17.jpeg'} alt={'17'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/18.jpeg'} alt={'18'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/19.jpeg'} alt={'19'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/20.jpeg'} alt={'20'} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/efffatha-urodziny-2019/21.jpeg'} alt={'21'} style={{ padding: '10px', margin: '0', width: '50%' }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
